<template>
     <section class="login-content">
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-7 align-self-center">
                              <div class="p-3">
                                 <h2 class="mb-2">Log-in</h2>
                                 <p>Login to stay connected.</p>
                                 <form @submit.prevent="onSubmit()"> 
                                    <router-link :to="{name: 'auth.recover-password'}" class="text-primary float-right">Forgot Password?</router-link>
                                    <p class="mt-3">
                                       Create an Account <router-link :to="{name: 'auth.register'}" class="text-primary">회원가입</router-link>
                                    </p>
                                 </form>
                              </div>
                           </div>
                           <div class="col-lg-5 content-right">
                              <img src="@/assets/images/login/01.png" class="img-fluid image-right" alt="">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import axios from 'axios'

export default {
   name:'SignFail',
   data() {
      return {
         companyid: "",
         email: "",
         pw: "",         
      }
   },
   methods: {
      onSubmit () {
         axios.post('/auth/sign-in/',{
            companyid:this.companyid,
            email:this.email,
            pw:this.pw,
            })
         .then(result => {
            console.log(result);
            this.$router.push({name: 'layout.dashboard1'})
         }).catch((e)=>{
            console.log(e);
         })
         
      }
   }
}
</script>